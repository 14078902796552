<template>
  <div class="listS">
    <img src="../assets/images/course.png" />
  </div>
</template>

<script>
export default {
}
</script>

<style scoped lang="scss">
  .listS {
    width: 100%;
    padding: 22px;
    box-sizing: border-box;
    img {
      width: 100%;
    }
  }
</style>
