<template>
  <div class="recommend">
    <div class="title">
      {{this.$route.name === 'Question' ? '相关问题' : '热门文章'}}
    </div>
    <div class="list">
      <ul>
        <li @click="this.$router.push('/detail')">怎样在碎片化的时间内最大化的提高学习效率？</li>
        <li @click="this.$router.push('/detail')">什么是计算机二级考试？怎样在碎片化的时间内最大化的提高学习效率？</li>
        <li @click="this.$router.push('/detail')">怎样在碎片化的时间内最大化的提高学习效率？</li>
        <li @click="this.$router.push('/detail')">什么是计算机二级考试？</li>
        <li @click="this.$router.push('/detail')">什么是计算机二级考试？怎样在碎片化的时间内最大化的提高学习效率？</li>
      </ul>
    </div>
  </div>
</template>

<script>
export default {
  name: 'Release'
}
</script>

<style scoped lang="scss">
.recommend {
  width: 294px;
  background: #fff;
  border-radius: 4px;
  overflow: hidden;
  position: relative;
  .title {
    width: 270px;
    height: 44px;
    line-height: 44px;
    margin: 0 12px;
    font-size: 14px;
    border-bottom: 1px solid #eeeeee;
  }
  .list {
    width: 100%;
    padding-bottom: 24px;
    li {
      cursor: pointer;
      padding: 0 12px;
      width: 100%;
      margin-top: 14px;
      font-size: 12px;
      line-height: 24px;
      box-sizing: border-box;
    }
  }
}
</style>
