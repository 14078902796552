<template>
  <div class="plate">
    <div class="img">
      <img src="../assets/images/pic.jpg" width="100%" />
    </div>
    <div class="info">
      <div class="title">
        计算机二级证书
      </div>
      <div class="desr">
        全国计算机等级考试（NationalComputerRankExamination，简称 NCRE），是为了考查考生的计算机应用知识和技能而设置的。经教育部批准，由教育部考试中心主办，要求参试者具有计算机应用知识以及办公软件高级应用能力…
      </div>
      <div class="tip">
        459内容 · 4969万浏览
      </div>
      <div class="btn">
        认证中🎖
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: 'Header'
}
</script>

<style scoped lang="scss">
.plate {
  width: 694px;
  height: 240px;
  background: #fff;
  border-radius: 4px;
  overflow: hidden;
  display: flex;
  position: relative;
  .img {
    width: 240px;
    height: 240px;
    display: flex;
    align-items: center; /*定义body的元素垂直居中*/
    justify-content: center; /*定义body的里的元素水平居中*/
    img {
      width: 100%;
    }
  }
  .info {
    flex: 1;
    margin: 16px 18px;
    .title {
      font-size: 22px;
      font-weight: 500;
    }
    .desr {
      font-size: 12px;
      margin-top: 12px;
      color: #121212;
      line-height: 22px;
    }
    .tip {
      color: #999999;
      margin-top: 82px;
      font-size: 12px;
    }
    .btn {
      position: absolute;
      bottom: 12px;
      right: 24px;
      width: 92px;
      height: 34px;
      line-height: 34px;
      background: #e5e5e5;
      color: #ffffff;
      font-size: 16px;
      text-align: center;
      border-radius: 2px;
    }
  }
}
</style>
