<template>
  <div class="home">
    <div class="_left">
      <Plate />
    </div>
    <div class="_right">
      <Release />
    </div>
  </div>
  <div class="home">
    <div class="_left">
      <!-- <Plate /> -->
      <div class="center">
        <div class="title">
          <label :class="{'select': this.active === 0}" @click="this.active = 0">推荐广场</label>
          <label :class="{'select': this.active === 1}" @click="this.active = 1">最新提问</label>
          <label :class="{'select': this.active === 2}" @click="this.active = 2">学习课程</label>
        </div>
        <ListArticle v-if="this.active === 0" />
        <ListQuestion v-if="this.active === 1" />
        <ListStudy v-if="this.active === 2" />
        <div class="more">
          加载更多...
        </div>
      </div>
    </div>
    <div class="_right">
      <Recommend />
    </div>
  </div>
</template>

<script>
// @ is an alias to /src
import Plate from '@/components/Plate.vue'
import Release from '../components/Release.vue'
import Recommend from '../components/Recommend.vue'
import ListArticle from '../components/ListArticle.vue'
import ListQuestion from '../components/ListQuestion.vue'
import ListStudy from '../components/ListStudy.vue'

export default {
  name: 'Home',
  components: {
    Plate,
    Release,
    Recommend,
    ListArticle,
    ListQuestion,
    ListStudy
  },
  data () {
    return {
      active: 0
    }
  }
}
</script>

<style lang="scss">
.home {
  width: 1000px;
  margin: 16px auto 0 auto;
  display: flex;
  ._left {
    width: 694px;
  }
  ._right {
    flex: 1;
    margin-left: 12px;
  }
  .center {
    width: 100%;
    background: #ffffff;
    border-radius: 4px;
    box-sizing: border-box;
    > .title {
      height: 44px;
      border-bottom: 1px solid #eeeeee;
      padding: 0 12px;
      label {
        cursor: pointer;
        height: 44px;
        line-height: 44px;
        font-size: 14px;
        margin: 0 12px;
        display: inline-block;
        &.select {
          border-bottom: 3px solid #417ff9;
        }
      }
    }
    .more {
      font-size: 12px;
      color: #999;
      padding: 22px 22px 42px;
      text-align: center;
      width: 100%;
    }
  }
}
</style>
