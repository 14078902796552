<template>
  <div class="header">
    <div class="wrap">
      <div class="logo" @click="this.$router.push('/')">
          <img src="../assets/images/logo.png" width="100%" />
      </div>
      <div class="user">
        <div class="img">
          <img src="../assets/images/user.png" width="100%" />
        </div>
        <div class="name">金小獴🎖</div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: 'Header'
}
</script>

<style scoped lang="scss">
.header {
  height: 52px;
  line-height: 52px;
  background: #ffffff;
  box-shadow: 0px 2px 5px 0px rgba(0,0,0,0.08);

  .wrap {
    width: 1000px;
    margin: 0 auto;
    display: flex;
    .logo {
      width: 128px;
      height: 52px;
      display: inline-block;
      cursor: pointer;
      img {
        width: 90%;
      }
    }
    .user {
      flex: 1;
      text-align: right;
      margin-right: 16px;

      .img {
        width: 32px;
        height: 32px;
        border-radius: 32px;
        vertical-align: middle;
        display: inline-block;
        margin-right: 3px;
        overflow: hidden;
        img {
          width: 100%;
        }
      }
      .name {
        display: inline-block;
        vertical-align: middle;
        font-size: 14px;
      }
    }
  }
}
</style>
