<template>
  <Header />
  <div class="contain">
    <router-view/>
  </div>
</template>

<script>
// @ is an alias to /src
import Header from '@/components/Header.vue'

export default {
  name: 'Home',
  components: {
    Header
  }
}
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style lang="scss">
*, body {
  margin: 0;
  padding: 0;
  font-size: 12px;
  color: #444444;
}
body {
  background: #F6F6F6;
}
ul,li {
  list-style: none;
}
img {
  width: 100%;
}
</style>
