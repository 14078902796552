<template>
  <div class="release">
    <div class="btn">
      <img src="../assets/images/icon4.png" /><em>问个问题</em>
    </div>
  </div>
</template>

<script>
export default {
  name: 'Release'
}
</script>

<style scoped lang="scss">
.release {
  width: 294px;
  height: 240px;
  background: #fff;
  border-radius: 4px;
  overflow: hidden;
  position: relative;
  .btn {
    width: 127px;
    height: 47px;
    line-height: 47px;
    background: #417ff9;
    color: #ffffff;
    font-size: 16px;
    text-align: center;
    margin: 94px auto;
    border-radius: 2px;
    cursor: pointer;
    &:hover {
      background: #376fdd;
    }
    img {
      width: 22px;
      height: 22px;
      vertical-align: middle;
      margin-right: 3px;
    }
    em {
      vertical-align: middle;
      color: #ffffff;
      font-size: 16px;
      font-style: normal;
    }
  }
}
</style>
