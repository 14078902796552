<template>
  <div class="listA">
    <ul>
      <li @click="jump">
        <div class="title">
          <img src="../assets/images/user.png" v-if="this.$route.name === 'Question'" />
          {{this.$route.name === 'Question' ? '一朵小红花🎖' : '什么是计算机二级证书？'}}
        </div>
        <div class="text">
          <p>
            全国计算机等级考试（NationalComputerRankExamination，简称 NCRE），是为了考查考生的计算机应用知识和技能而设置的。经教育部批准，由教育部考试中心主办，要求参试者具有计算机应用知识以及办公软件高级应用能力…
          </p>
        </div>
        <div class="info">
          <div class="name">{{this.$route.name !== 'Question' ? '来自 一朵小红花' : ''}}</div>
          <div class="action">
            <label><img src="../assets/images/icon1.png" /><em>1点赞</em></label>
            <label><img src="../assets/images/icon3.png" /><em>2收藏</em></label>
            <label><img src="../assets/images/icon2.png" /><em>3评论</em></label>
          </div>
        </div>
      </li>
      <li @click="jump">
        <div class="title">
          <img src="../assets/images/user.png" v-if="this.$route.name === 'Question'" />
          {{this.$route.name === 'Question' ? '一朵小红花🎖' : '什么是计算机二级证书？'}}
        </div>
        <div class="text">
          <img src="../assets/images/pic.jpg" width="100%" v-if="this.$route.name !== 'Question'"/>
          <p>
            全国计算机等级考试（NationalComputerRankExamination，简称 NCRE），是为了考查考生的计算机应用知识和技能而设置的。经教育部批准，由教育部考试中心主办，要求参试者具有计算机应用知识以及办公软件高级应用能力…
          </p>
        </div>
        <div class="info">
          <div class="name">{{this.$route.name !== 'Question' ? '来自 一朵小红花' : ''}}</div>
          <div class="action">
            <label><img src="../assets/images/icon1.png" /><em>1点赞</em></label>
            <label><img src="../assets/images/icon3.png" /><em>2收藏</em></label>
            <label><img src="../assets/images/icon2.png" /><em>3评论</em></label>
          </div>
        </div>
      </li>
      <li @click="jump">
        <div class="title">
          <img src="../assets/images/user.png" v-if="this.$route.name === 'Question'" />
          {{this.$route.name === 'Question' ? '一朵小红花🎖' : '什么是计算机二级证书？'}}
        </div>
        <div class="text">
          <p>
            全国计算机等级考试（NationalComputerRankExamination，简称 NCRE），是为了考查考生的计算机应用知识和技能而设置的。经教育部批准，由教育部考试中心主办，要求参试者具有计算机应用知识以及办公软件高级应用能力…
          </p>
        </div>
        <div class="info">
          <div class="name">{{this.$route.name !== 'Question' ? '来自 一朵小红花' : ''}}</div>
          <div class="action">
            <label><img src="../assets/images/icon1.png" /><em>1点赞</em></label>
            <label><img src="../assets/images/icon3.png" /><em>2收藏</em></label>
            <label><img src="../assets/images/icon2.png" /><em>3评论</em></label>
          </div>
        </div>
      </li>
      <li @click="jump">
        <div class="title">
          <img src="../assets/images/user.png" v-if="this.$route.name === 'Question'" />
          {{this.$route.name === 'Question' ? '一朵小红花🎖' : '什么是计算机二级证书？'}}
        </div>
        <div class="text">
          <img src="../assets/images/pic.jpg" width="100%" v-if="this.$route.name !== 'Question'"/>
          <p>
            全国计算机等级考试（NationalComputerRankExamination，简称 NCRE），是为了考查考生的计算机应用知识和技能而设置的。经教育部批准，由教育部考试中心主办，要求参试者具有计算机应用知识以及办公软件高级应用能力…
          </p>
        </div>
        <div class="info">
          <div class="name">{{this.$route.name !== 'Question' ? '来自 一朵小红花' : ''}}</div>
          <div class="action">
            <label><img src="../assets/images/icon1.png" /><em>1点赞</em></label>
            <label><img src="../assets/images/icon3.png" /><em>2收藏</em></label>
            <label><img src="../assets/images/icon2.png" /><em>3评论</em></label>
          </div>
        </div>
      </li>
      <li @click="jump">
        <div class="title">
          <img src="../assets/images/user.png" v-if="this.$route.name === 'Question'" />
          {{this.$route.name === 'Question' ? '一朵小红花🎖' : '什么是计算机二级证书？'}}
        </div>
        <div class="text">
          <p>
            全国计算机等级考试（NationalComputerRankExamination，简称 NCRE），是为了考查考生的计算机应用知识和技能而设置的。经教育部批准，由教育部考试中心主办，要求参试者具有计算机应用知识以及办公软件高级应用能力…
          </p>
        </div>
        <div class="info">
          <div class="name">{{this.$route.name !== 'Question' ? '来自 一朵小红花' : ''}}</div>
          <div class="action">
            <label><img src="../assets/images/icon1.png" /><em>1点赞</em></label>
            <label><img src="../assets/images/icon3.png" /><em>2收藏</em></label>
            <label><img src="../assets/images/icon2.png" /><em>3评论</em></label>
          </div>
        </div>
      </li>
    </ul>
  </div>
</template>

<script>
export default {
  methods: {
    jump () {
      this.$router.push('/detail')
    }
  }
}
</script>

<style scoped lang="scss">
  .listA {
    ul {
      li {
        cursor: pointer;
        padding: 20px 28px 12px 28px;
        border-bottom: 1px solid #eeeeee;
        .title {
          font-size: 15px;
          margin-bottom: 12px;
          color: #262626;
          font-weight: bold;
          img {
            width: 32px;
            height: 32px;
            vertical-align: middle;
          }
        }
        .text {
          color: #8C8C8C;
          line-height: 24px;
          margin-bottom: 12px;
          display: flex;
          img {
            width: 130px;
            height: 80px;
            margin-right: 12px;
          }
          p {
            flex: 1;
          }
        }
        .info {
          display: flex;
          .name {
            flex: 1;
            color: #BCBCBC;
          }
          .action {
            width: 200px;
            text-align: right;
            label {
              color: #585858;
              margin-left: 12px;
              vertical-align: middle;
            }
            img {
              width: 14px;
              height: 14px;
              vertical-align: middle;
              margin-right: 3px;
            }
            em {
              vertical-align: middle;
              font-style: normal;
            }
          }
        }
      }
    }
  }
</style>
