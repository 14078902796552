<template>
  <div class="listQ">
    <div class="nav">
      <label :class="{'select': this.active === 0}" @click="this.active = 0">问题广场</label>
      <label :class="{'select': this.active === 1}" @click="this.active = 1">我的关注</label>
    </div>
    <ul v-if="!active">
      <li @click="this.$router.push('/question')">
        <div class="title">
          什么是计算机二级考试？怎样在碎片化的时间内最大化的提高学习效率？
        </div>
        <div class="info">
          <div class="name">280 浏览 · 117 人想知道答案</div>
          <div class="action">
            <img src="../assets/images/icon4.png" /><em>关注问题</em>
          </div>
        </div>
      </li>
      <li @click="this.$router.push('/question')">
        <div class="title">
          怎样在碎片化的时间内最大化的提高学习效率？
        </div>
        <div class="info">
          <div class="name">280 浏览 · 117 人想知道答案</div>
          <div class="action">
            <img src="../assets/images/icon4.png" /><em>关注问题</em>
          </div>
        </div>
      </li>
      <li @click="this.$router.push('/question')">
        <div class="title">
          什么是计算机二级考试？怎样在碎片化的时间内最大化的提高学习效率？
        </div>
        <div class="info">
          <div class="name">280 浏览 · 117 人想知道答案</div>
          <div class="action">
            <img src="../assets/images/icon4.png" /><em>关注问题</em>
          </div>
        </div>
      </li>
      <li @click="this.$router.push('/question')">
        <div class="title">
          什么是计算机二级考试？
        </div>
        <div class="info">
          <div class="name">280 浏览 · 117 人想知道答案</div>
          <div class="action">
            <img src="../assets/images/icon4.png" /><em>关注问题</em>
          </div>
        </div>
      </li>
    </ul>

    <ul v-if="active">
      <li @click="this.$router.push('/question')">
        <div class="title">
          什么是计算机二级考试？怎样在碎片化的时间内最大化的提高学习效率？
        </div>
        <div class="info">
          <div class="name">280 浏览 · 117 人想知道答案</div>
        </div>
      </li>
      <li @click="this.$router.push('/question')">
        <div class="title">
          怎样在碎片化的时间内最大化的提高学习效率？
        </div>
        <div class="info">
          <div class="name">280 浏览 · 117 人想知道答案</div>
        </div>
      </li>
      <li @click="this.$router.push('/question')">
        <div class="title">
          什么是计算机二级考试？怎样在碎片化的时间内最大化的提高学习效率？
        </div>
        <div class="info">
          <div class="name">280 浏览 · 117 人想知道答案</div>
        </div>
      </li>
      <li @click="this.$router.push('/question')">
        <div class="title">
          什么是计算机二级考试？
        </div>
        <div class="info">
          <div class="name">280 浏览 · 117 人想知道答案</div>
        </div>
      </li>
    </ul>
  </div>
</template>

<script>
export default {
  data () {
    return {
      active: 0
    }
  }
}
</script>

<style scoped lang="scss">
  .listQ {
    .nav {
      padding: 22px 0 0 28px;
      label {
        width: 86px;
        height: 28px;
        line-height: 28px;
        background: #F6F6F6;
        color: #999999;
        border-radius: 18px;
        display: inline-block;
        text-align: center;
        margin-right: 12px;
        cursor: pointer;
        &.select {
          background: #417ff9;
          color: #ffffff;
        }
      }
    }
    ul {
      li {
        cursor: pointer;
        padding: 28px 28px 18px 28px;
        border-bottom: 1px solid #eeeeee;
        .title {
          font-size: 15px;
          margin-bottom: 12px;
          color: #262626;
          font-weight: bold;
        }
        .text {
          color: #8C8C8C;
          line-height: 24px;
          margin-bottom: 12px;
          display: flex;
          img {
            width: 130px;
            height: 80px;
            margin-right: 12px;
          }
          p {
            flex: 1;
          }
        }
        .info {
          display: flex;
          margin-top: 22px;
          .name {
            flex: 1;
            color: #BCBCBC;
          }
          .action {
            width: 96px;
            background: #417ff9;
            color: #ffffff;
            font-size: 13px;
            text-align: center;
            border-radius: 2px;
            cursor: pointer;
            &:hover {
              background: #376fdd;
            }
            img {
              width: 18px;
              height: 18px;
              vertical-align: middle;
              margin-right: 3px;
            }
            em {
              vertical-align: middle;
              font-style: normal;
              color: #ffffff;
              font-size: 13px;
              height: 36px;
              line-height: 36px;
            }
          }
        }
      }
    }
  }
</style>
